var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",staticStyle:{"overflowY":"auto","height":"100%","width":"100%"},attrs:{"fluid":""}},[_c('v-data-iterator',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"items":_vm.occurrences.items,"loading":_vm.isLoading,"server-items-length":_vm.occurrences.total,"hide-default-footer":""},on:{"update:page":_vm.onPageNumberUpdated},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"pa-3 pb-4 d-flex align-center",staticStyle:{"border-bottom":"1px solid"},style:({
          borderColor: _vm.$vuetify.theme.themes.light.border,
        })},[_c('input-text',{attrs:{"max-width":"440px","left-icon":"mdi-magnify","placeholder":_vm.$t('deconve.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),[_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":_vm.$vuetify.breakpoint.mobile,"disabled":_vm.isActiveDownloadButton},on:{"click":_vm.getDownloadOccurrencesReport}},[(!_vm.isDownloadingOccurrences)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"width":2.5,"color":"neutral"}})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-icon',_vm._b({attrs:{"color":"error"},on:{"click":function($event){_vm.isToShowDownloadErrorMessage = false}}},'v-icon',attrs,false),[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])]}}]),model:{value:(_vm.isToShowDownloadErrorMessage),callback:function ($$v) {_vm.isToShowDownloadErrorMessage=$$v},expression:"isToShowDownloadErrorMessage"}},[_c('span',[_vm._v(_vm._s(_vm.$t('deconve.error.internalError')))])])],_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",class:_vm.hasFilterChanged ? 'primaryHighlight' : 'white',attrs:{"icon":"","color":_vm.hasFilterChanged ? 'primary' : 'neutral',"small":_vm.$vuetify.breakpoint.mobile}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.hasFilterChanged ? 'mdi-filter':'mdi-filter-outline')+" ")])],1)]}}])},[_c('occurrences-filter',{attrs:{"has-changed":_vm.hasFilterChanged},on:{"change":_vm.onFilterChanged,"clear":_vm.clearFilters},model:{value:(_vm.filterOptions),callback:function ($$v) {_vm.filterOptions=$$v},expression:"filterOptions"}})],1),_c('date-time-range-picker',{attrs:{"start-date-time":_vm.filterOptions.createdAfter,"end-date-time":_vm.filterOptions.createdBefore,"mode":_vm.filterOptions.createdDateOption},on:{"change":_vm.onCreatedAtDateChanged}})],2)]},proxy:true},{key:"loading",fn:function(){return [_c('span',{staticClass:"ml-1 pa-2"},[_vm._v(" "+_vm._s(_vm.$t('deconve.loading'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [_c('span',{staticClass:"ml-1 pa-2 neutralPrimary--text\n      text-subtitle-2 text-sm-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('deconve.noOccurrence'))+" ")])]},proxy:true},{key:"default",fn:function(props){return [_c('div',{staticStyle:{"flex":"1","overflowY":"auto"},style:({
          backgroundColor: _vm.$vuetify.theme.themes.light.background,
        })},[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap pa-2"},_vm._l((props.items),function(item){return _c('v-col',{key:item.id,staticClass:"pa-2",staticStyle:{"height":"fit-content"},attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('occurrence-preview',{key:item.id,attrs:{"id":item.id,"data":item}})],1)}),1)],1)]}},{key:"footer",fn:function(props){return [_c('footer-data-iterator',{attrs:{"items-per-page-array":_vm.itemsPerPageArray,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"total-data":props.pagination.itemsLength},on:{"changeItensPerPage":_vm.updateItemsPerPage,"changePage":_vm.onPageNumberUpdated}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }